export const sections = [
  {
    title: "Desenvolvimento Web Full Stack da Trybe com Eletiva em C#",
    skills: [
      "Fundamentos do Desenvolvimento Web: Adquiri uma sólida compreensão dos fundamentos da programação, incluindo o uso de ferramentas de controle de versão como Git e GitHub, e linguagens de marcação como HTML e CSS.",
      "JavaScript e Lógica de Programação: Aprimorei minha habilidade em JavaScript, a linguagem de programação mais utilizada na web. Aprendi a escrever código limpo e eficiente, utilizando conceitos avançados de lógica de programação.",
      "Desenvolvimento Front-End: Desenvolvi a capacidade de criar interfaces de usuário ricas e interativas utilizando bibliotecas e frameworks populares como React. Também aprendi a trabalhar com APIs para criar aplicações web dinâmicas.",
      "Desenvolvimento Back-End: Dominei o desenvolvimento de aplicações server-side utilizando Node.js e Express. Aprendi a criar e gerenciar bancos de dados usando SQL e MongoDB, e a implementar autenticação e autorização.",
      "Eletiva em C#: Como uma opção de eletiva, tive a oportunidade de aprender C#, uma linguagem de programação poderosa e versátil utilizada para desenvolver uma variedade de aplicações, desde jogos até aplicações empresariais.",
      "Metodologias Ágeis: Compreendi a importância das metodologias ágeis, como Scrum e Kanban, na gestão de projetos de desenvolvimento de software.",
      "Soft Skills: Além das habilidades técnicas, o curso da Trybe também me ajudou a desenvolver soft skills, como trabalho em equipe, comunicação eficaz e resolução de problemas.",
      "Ao concluir o curso de Desenvolvimento Web Full Stack da Trybe com eletiva em C#, estou equipado com um conjunto de habilidades altamente demandadas no mercado de trabalho atual."
    ]
  },
  {
    title: "Em breve",
    skills: ["..."]
  }
];
