import React, { useState, useRef, useEffect } from "react";
import { sections } from "../../data/skillsdata";
import "./Skills.css";

const Skills = () => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const skillsContentRef = useRef(null);

  const scrollToTop = () => {
    if (skillsContentRef.current) {
      skillsContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [currentIndex, currentSectionIndex]);

  const itemsPerPage = 3;
  const currentSkills = sections[currentSectionIndex].skills.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  const totalPages = Math.ceil(
    sections[currentSectionIndex].skills.length / itemsPerPage
  );
  const currentPage = Math.ceil(currentIndex / itemsPerPage) + 1;

  const handleNextSkills = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < sections[currentSectionIndex].skills.length - itemsPerPage
        ? prevIndex + itemsPerPage
        : prevIndex
    );
    scrollToTop();
  };

  const handlePrevSkills = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - itemsPerPage : prevIndex
    );
    scrollToTop();
  };

  const handleNextSection = () => {
    setCurrentSectionIndex((prevIndex) =>
      prevIndex < sections.length - 1 ? prevIndex + 1 : prevIndex
    );
    setCurrentIndex(0);
  };

  const handlePrevSection = () => {
    setCurrentSectionIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
    setCurrentIndex(0);
  };

  return (
    <section className="skills-container">
      <div className="skills-img"></div>
      <button
        className="section-button"
        onClick={handlePrevSection}
        disabled={currentSectionIndex === 0}
      >
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <div className="skills-content" ref={skillsContentRef}>
        <h2>{sections[currentSectionIndex].title}</h2>
        <ul>
          {currentSkills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
        <div className="navigation-buttons">
          <button onClick={handlePrevSkills} disabled={currentIndex === 0}>
            <i class="fa-solid fa-circle-left"></i>
          </button>
          <span className="page-info">
            {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextSkills}
            disabled={
              currentIndex >=
              sections[currentSectionIndex].skills.length - itemsPerPage
            }
          >
            <i class="fa-solid fa-circle-right"></i>
          </button>
        </div>
      </div>
      <button
        className="section-button"
        onClick={handleNextSection}
        disabled={currentSectionIndex >= sections.length - 1}
      >
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </section>
  );
};

export default Skills;
