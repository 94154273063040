import recipesappgif from "./img/recipesappgif.gif";
import shoppingcart from "./img/shoppingcart.gif";
import apideblog from "./img/apideblog.jpeg";

export const projectsData = [
  {
    title: "App de Receitas",
    description:
      "Este é um aplicativo de receitas desenvolvido utilizando React, onde é possível visualizar, buscar, filtrar, favoritar e acompanhar o progresso de preparação de receitas de comidas e bebidas.",
    technologies:
      "Tecnologias: React, Redux, React-Redux, Context API do React, React Hooks, CSS",
    imageUrl: recipesappgif,
    link: "https://github.com/GabrielReis1/Recipes-App"
  },
  {
    title: "Carrinho de compras",
    description:
      "Este projeto consiste em um carrinho de compras totalmente dinâmico, onde os produtos são obtidos diretamente da API do Mercado Livre. Utilizando a metodologia de desenvolvimento orientado a testes (TDD - Test Driven Development), garantimos a confiabilidade e qualidade do código.",
    technologies:
      "Tecnologias: Frontend: HTML, CSS, JavaScript, API: Mercado Livre, awesomeapi e BrasilAPI; Teste: Jest",
    imageUrl: shoppingcart,
    link: "https://github.com/GabrielReis1/Shopping-Cart"
  },
  {
    title: "Api de Blogs",
    description:
      "Este projeto consiste no desenvolvimento de uma API e um banco de dados para a produção de conteúdo para um blog. A aplicação foi desenvolvida em Node.js utilizando o pacote Sequelize para realizar operações CRUD (Create, Read, Update, Delete) de posts.",
    technologies:
      "Tecnologias: Node.js, Express.js, Sequelize (ORM), MYSQL (ou outro banco de dados SQL de sua escolha), JWT (JSON Web Tokens) para autenticação",
    imageUrl: apideblog,
    link: "https://github.com/GabrielReis1/Blogs-api"
  }
  // Adicione mais projetos conforme necessário
];
