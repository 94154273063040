import { useLocation } from 'react-router-dom';

const useActiveLink = () => {
  const location = useLocation();

  return (path) => {
    return location.pathname === path ? 'active' : '';
  };
};

export default useActiveLink;
