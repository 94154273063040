import React, { useState, useEffect } from "react";
import "./Education.css";
import { certificates } from "../../data/certificates";

const Education = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section>
      {loading ? (
        <div className="loading-container">
          <div className="loading">Loading...</div>
        </div>
      ) : (
        <div className="certificate-container">
          {certificates.map((cert) => (
            <div className="certificate" key={cert.id}>
              <img src={cert.imageUrl} alt={cert.title} />
              <div className="certificate-info">
                <h3>{cert.title}</h3>
                <p>{cert.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Education;
