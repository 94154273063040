export const certificates = [
  {
    id: 1,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/89211462",
    title: "Desenvolvimento WEB Full-Stack",
    description: "Conclui com sucesso a Formação em Desenvolvimento Web Full-Stack da Trybe, com duração mínima de 12 meses e carga horária de 1.500 horas/aula. Durante esse período, me aprofundei e apliquei de forma prática conteúdos de: Fundamentos do Desenvolvimento Web, Front-End e Back-End em Javascript; Ciência da Computação em Python; Habilidades de Soft-skills; Metodologias Ágeis e complementou sua formação com uma Certificação Eletiva em C#."
  },
  {
    id: 2,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/67161203",
    title: "Fundamentos do Desenvolvimento Web",
    description: "Neste módulo da Trybe, aprenbdi e desenvolvi conteúdos relacionados a Fundamentos do Desenvolvimento Web, aplicando de forma prática conteúdos como: Unix & Bash, Git, JS Básico & DOM, HTML, CSS, JS ES6, Higher Order Functions e Testes Unitários, assim como metodologias ágeis e habilidades comportamentais."
  },
  {
    id: 3,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/73742796",
    title: "Desenvolvimento em Front-End",
    description: "Neste módulo da Trybe, aprenbdi e desenvolvi conteúdos relacionados a desenvolvimento Front-end, React, incluindo Componentes, Estado e Eventos, Componentes Controlados, Ciclo de Vida, Router, Testes com RTL, Redux com React, Context API, React Hooks, metodologias ágeis e habilidades comportamentais."
  },
  {
    id: 4,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/80939823",
    title: "Desenvolvimento em Back End",
    description: "Neste módulo da formação em Desenvolvimento Web da Trybe, me aprofundei e apliquei de forma prática conteúdos como: Docker, MySQL, Node.js & Express, Testes Unitários e de Integração com Mocha, Chai e Sinon, TypeScript, Arquitetura em Camadas, REST, JWT, ORM com Sequelize, Deployment, POO e princípios SOLID."
  },
  {
    id: 5,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/83448367",
    title: "Ciência da Computação",
    description: "Neste módulo da formação em Desenvolvimento Web da Trybe, me aprofundei e apliquei de forma prática conteúdos como: Complexidade de Algoritmos, Algoritmos de Ordenação e Busca, Recursividade & Estratégias para Resolução de Problemas, Estruturas de Dados, OOP e Python"
  },
  {
    id: 6,
    imageUrl: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/88383926",
    title: "Certificação Eletiva em C#",
    description: "Durante este período, em uma carga horária de 170 horas/aula, me aprofundei e apliquei de forma prática conteúdos como: .NET Core, Criação de APIs com ASP.NET, Testes Unitários com xUnit, Banco de Dados com SQL Server, Entity Framework, Autenticação e Autorização, Collections & LINQ, Docker, Arquitetura de Microsserviços e Deployment com Azure. Além disso, conclui 100 horas/aula de conteúdos revisionais de Carreira, incluindo revisões de Front-end e Back-end e um desafio full stack."
  }
];