import React, { useState } from "react";
import { Link } from "react-router-dom";
import useActiveLink from "../hooks/useActiveLink";
import "./DropdownNav.css";

const DropdownNav = () => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const toggleDropdown = () => setIsDropdownActive(!isDropdownActive);

  const getActiveClass = useActiveLink();

  return (
    <nav className="nav-bar">
      <div className="dropdown-btn" onClick={toggleDropdown}>
        <p>Menu</p>
        &#9776;
      </div>
      <ul
        className={`nav-links ${isDropdownActive ? "mobile-nav active" : ""}`}
      >
        <NavLinkItem to="/" label="Sobre Mim" getActiveClass={getActiveClass} />
        <NavLinkItem
          to="/skills"
          label="Habilidades"
          getActiveClass={getActiveClass}
        />
        <NavLinkItem
          to="/education"
          label="Certificações"
          getActiveClass={getActiveClass}
        />
        <NavLinkItem
          to="/projects"
          label="Projetos"
          getActiveClass={getActiveClass}
        />
        <NavLinkItem
          to="/contact"
          label="Contato"
          getActiveClass={getActiveClass}
        />
      </ul>
    </nav>
  );
};

const NavLinkItem = ({ to, label, getActiveClass }) => (
  <li className={getActiveClass(to)}>
    <Link to={to}>{label}</Link>
  </li>
);

export default DropdownNav;
