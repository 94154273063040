import React, { useRef } from "react";
import "./AboutMe.02.css";

const AboutMe = () => {
  const aboutMeRef = useRef(null);

  const scrollToTop = () => {
    if (aboutMeRef.current) {
      aboutMeRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <section className="section-container">
      <div className="section-img"></div>
      <div className="section-text" ref={aboutMeRef}>
        <h2>Sobre Mim</h2>
        <p>
          Olá! Sou Gabriel, Desenvolvedor Web Full-Stack em transição de
          carreira e estou em busca de oportunidades para aplicar meus
          conhecimentos em programação. Com um histórico profissional
          diversificado, decidi buscar novos desafios e encontrei na tecnologia
          a oportunidade perfeita para me reinventar.
        </p>
        <p>
          Estou empenhado em aprender o máximo que puder e me desenvolver como
          Desenvolvedor Web Full Stack, com foco em front-end. Durante meu
          período na Trybe, adquiri habilidades em programação orientada a
          objetos, estruturas de dados, algoritmos, HTML, CSS, JavaScript, React
          e outras tecnologias essenciais para o desenvolvimento web.
        </p>
        <p>
          Também tenho trabalhado em projetos em equipe, o que me permite
          desenvolver habilidades de comunicação, colaboração e liderança. Estou
          procurando uma oportunidade para aplicar essas habilidades em um
          ambiente de trabalho desafiador e colaborativo, onde possa continuar
          aprendendo e contribuindo para o sucesso da equipe. Estou aberto a
          oportunidades de estágio, trainee ou júnior, e estou ansioso para
          explorar as possibilidades de carreira em tecnologia.
        </p>
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <i class="fa-solid fa-circle-up"></i>
        </button>
      </div>
    </section>
  );
};

export default AboutMe;
