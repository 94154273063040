import React from "react";
import "./Contact.css";

const Contact = () => (
  <section>
    <div className="contact-container">
      <div className="contact-content">
        <p>
          <a href="mailto:gabrielreis.santo@outlook.com">
            <i className="fas fa-envelope"></i>
            <p>outlook</p>
          </a>
        </p>
        <p>
          <a
            href="https://www.linkedin.com/in/gabrielreisdev/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
            <p>linkedin</p>
          </a>
        </p>
        <p>
          <a
            href="https://github.com/GabrielReis1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github"></i>
            <p>GitHub</p>
          </a>
        </p>
        {/* Adicione mais informações de contato conforme necessário */}
      </div>
    </div>
  </section>
);

export default Contact;
