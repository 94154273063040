import React, { useState, useEffect } from "react";
import { projectsData } from "../../data/projects";
import "./Projects.css";

const Projects = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <section className="projects-section">
      {loading ? (
        <div className="loading-container">
          <div className="loading">Loading...</div>
        </div>
      ) : (
        projectsData.map((project, index) => (
          <div className="project" key={index}>
            <h1>{project.title}</h1>
            <img src={project.imageUrl} alt={project.title} />
            <p>{project.description}</p>
            <p>{project.technologies}</p>
            <a href={project.link}>Link para o projeto</a>
          </div>
        ))
      )}
    </section>
  );
};

export default Projects;
